<template>
  <v-row justify="center">
    <v-dialog
      content-class="dialog"
      max-width="484"
      min-height="205"
      :value="show"
      @click:outside="$emit('close')"
    >
      <v-card>
        <div class="title">Загрузить новый сценарий?</div>
        <div class="subtitle">Текущий сценарий будет удален навсегда!</div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="height: 42px"
            color="black"
            text
            @click="$emit('close')"
          >
            Отменить
          </v-btn>

          <v-btn class="submin-btn" text @click="$refs.file.click()">
            Выбрать файл
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input
      v-show="false"
      class="btnUpload__input"
      type="file"
      :id="'file'"
      ref="file"
      v-on:change="handleFileUpload()"
      accept="application/JSON"
    />
  </v-row>
</template>

<script>
import axios from 'axios'
export default {
  props: ["show"],
  data() {
    return {
      file: {
        ref: "",
        name: "",
      },
    };
  },
  methods: {
    handleFileUpload() {
      this.file.ref = this.$refs.file.files[0];
      this.file.name = this.$refs.file.files[0].name;

      this.importScenario();
    },
    // загрузить новый сценарий в формате json
    importScenario() {
      const formData = new FormData();
      formData.append("file", this.file.ref);
      return new Promise((resolve, reject) => {
        axios
          .post("/blocks/import", formData)
          .then((resp) => {
            this.$emit("imported", resp.data.url);
            this.$refs.file.value = "";
            resolve(resp);
          })
          .catch((err) => {
            this.$refs.file.value = "";
            reject(err);
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .v-card {
    width: 484px !important;
    min-height: 205px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start !important;
    padding: 30px 23px 26px 30px;
    &__actions {
      align-self: flex-end;
      padding: 0 !important;
      margin-top: 17px;
    }
  }
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #333333;
  }
  .subtitle {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    max-width: 342px;
    margin-top: 16px;
  }
  .submin-btn {
    height: 42px;
    color: white;
    background: #006FC0;
  }
}
</style>